export default function Footer() {
  return (
    <div className="footer footer-black">
      <div className="footer-links">
        <a style={{ color: "#a6a6a6" }} className="footer-link" href="/">
          <p>Strona główna</p>
        </a>

        <a
          style={{ color: "#a6a6a6" }}
          className="footer-link"
          href="/#/kontakt"
        >
          <p>Kontakt</p>
        </a>

        <a
          style={{ color: "#a6a6a6" }}
          className="footer-link"
          href="https://discord.gg/kmAntaQEvc"
        >
          <p>Serwer Discord</p>
        </a>

        <a
          style={{ color: "#a6a6a6" }}
          className="footer-link"
          href="https://www.youtube.com/@wynszer/"
        >
          <p>Kanał YouTube</p>
        </a>
      </div>
    </div>
  );
}
