import Card from "../Components/Card";

export default function Kontakt(props: {
  contacts: {
    name: string;
    link: string;
    img: string;
    desc: string;
    img_alt: string;
  }[];
}) {
  return (
    <>
      <div className="part">
        <h1 className="display-4 text-white weight-400 head-title">Kontakt</h1>
        <div className="cards">
          {props.contacts.map((kontakt) => {
            return (
              <Card
                img={kontakt.img}
                link={kontakt.link}
                title={kontakt.name}
                description={kontakt.desc}
                img_alt={kontakt.img_alt}
              ></Card>
            );
          })}
        </div>
      </div>
    </>
  );
}
