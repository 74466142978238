import { createHashRouter, RouterProvider } from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./Components/Navbar";
import Main from "./Pages/Main";
import Kontakt from "./Pages/Kontakt";
import NotFound from "./Pages/notfound404";
import Footer from "./Components/Footer";

import yt_logo from "./assets/yt.png";
import dc_logo from "./assets/discord.png";
import discordjs_logo from "./assets/discordjs.png";
import shorts_logo from "./assets/shorts.png";
import bg from "./assets/bg.jpg";
import gmail_logo from "./assets/gmail.png";
import instagram_logo from "./assets/instagram.png";

const discord_link = "https://discord.gg/kmAntaQEvc";

const router = createHashRouter([
  {
    path: "/",
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: (
          <Main
            cards={[
              {
                img: yt_logo,
                img_alt: "logo",
                title: "Kanał na YouTube",
                description:
                  "Pierwszy film wrzucony 22 września 2023, od tego czasu kanał rozwija się dynamicznie.",
                link: "https://www.youtube.com/@wynszer/",
              },
              {
                img: dc_logo,
                img_alt: "screenshot",
                title: "Serwer Discord",
                description: "Z fantastyczną społecznością i administracją ❤️",
                link: discord_link,
              },
              {
                img: discordjs_logo,
                img_alt: "logo discord js",
                title: "Klika botów Discord",
                description:
                  "Takich jak Instacord, NetCord, Macros czy Dismatch (wciąż się uczę).",
                link: "https://discord.js.org/",
              },
              {
                img: shorts_logo,
                img_alt: "logo youtube shorts",
                title: "YouTube shorts",
                description:
                  "Shorty, które uzyskały w sumie ponad 2 mln wyświetleń.",
                link: "https://www.youtube.com/@wynszer/",
              },
            ]}
          />
        ),
      },
      {
        path: "/kontakt",
        element: (
          <Kontakt
            contacts={[
              {
                name: "Discord",
                desc: "wynszer",
                img: dc_logo,
                img_alt: "logo discord",
                link: discord_link,
              },
              {
                name: "Email",
                desc: "wynszer.biznes@gmail.com",
                img: gmail_logo,
                img_alt: "logo gmail",
                link: "https://gmail.com",
              },
              {
                name: "Serwer Discord",
                desc: discord_link,
                img: dc_logo,
                img_alt: "logo discord",
                link: discord_link,
              },
              {
                name: "Instagram",
                desc: "wynszer",
                img: instagram_logo,
                img_alt: "logo instagram",
                link: "https://www.instagram.com/wynszer/",
              },
            ]}
          />
        ),
      },
      { path: "/*", element: <NotFound /> },
    ],
  },
]);

function App() {
  useEffect(() => {
    document.body.style.backgroundColor = "#060606";
    document.body.style.backgroundImage = `url(${bg})`;
  });

  return (
    <div className="App">
      <Navbar />
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
