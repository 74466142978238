import catjam from "../assets/cat.gif";

export default function NotFound() {
  return (
    <>
      <div className="part">
        <h1 className="text-white xl-font">404</h1>
        <p className="text-white">
          Nie znaleziono strony, której szukasz. Masz kotka na pocieszenie.
        </p>
        <img
          src={catjam}
          style={{ marginBottom: "100%" }}
          className="large-logo"
          alt="tańczący kotek"
        />
      </div>
      <p className="text-white text-center">UUU detektyw!</p>
      <a href="/">
        <div className="footer sticky-bottom">Powrót na stronę główną</div>
      </a>
    </>
  );
}
