export default function Card(props: {
  img: string;
  img_alt: string;
  title: string;
  description: string;
  link: string;
}) {
  return (
    <a href={props.link}>
      <div className="card" data-bs-theme="dark">
        <img src={props.img} className="card-img-top" alt={props.img_alt} />
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
        </div>
      </div>
    </a>
  );
}
