import logo from "../assets/logo.jpg";

export default function Navbar() {
  return (
    <>
      <nav className="navbar bg-black " data-bs-theme="dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="Logo"
              width="30"
              height="30"
              className="d-inline-block align-text-top logo m-r-10"
            />
            Wynszer
          </a>
          <a className="navbar-brand" href="/#/kontakt">
            Kontakt
          </a>
        </div>
      </nav>
    </>
  );
}
