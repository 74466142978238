import catjam from "../assets/cat.gif";
import Card from "../Components/Card";
import arrow from "../assets/arrow.png";
import { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function MainPage(props: {
  cards: {
    img: string;
    img_alt: string;
    title: string;
    description: string;
    link: string;
  }[];
}) {
  const [speaking, setSpeaking] = useState("");

  const minTimeSpeak = 1.3;
  const maxTimeSpeak = 3;

  const minTimeSilent = 3;
  const maxTimeSilent = 8;

  useEffect(() => {
    var randomTime =
      (Math.floor(Math.random() * (maxTimeSilent - minTimeSilent)) +
        minTimeSilent) *
      1000;
    setTimeout(() => {
      var randomTime2 =
        (Math.floor(Math.random() * (maxTimeSpeak - minTimeSpeak)) +
          minTimeSpeak) *
        1000;
      setSpeaking("speaking");
      setTimeout(() => {
        setSpeaking("");
      }, randomTime2);
    }, randomTime);
  });

  const start = useRef<null | HTMLDivElement>(null);
  const handleClickStart = () => {
    start.current?.scrollIntoView({ behavior: "smooth" });
  };

  const ref = useRef<null | HTMLDivElement>(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const ref2 = useRef<null | HTMLDivElement>(null);
  const handleClick2 = () => {
    ref2.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div ref={start} className="part">
        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <a href="/#/kontakt">
            <img
              src={catjam}
              alt="Cat"
              className={`hover-event logo large-logo ` + speaking}
              width="100"
              height="100"
            />
          </a>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <h1 className="text-white text-center heading ">Wynszer</h1>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <p className="text-white desc ">
            Robię filmy na YouTube o <mark>Discord</mark>.
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <p className="scroll-arrows " onClick={handleClick}>
            <img src={arrow} alt="strzałka" className="arrows" />
          </p>
        </ScrollAnimation>
      </div>

      <div className="part">
        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <div ref={ref} className="text-right">
            <h1 className="display-4 text-white weight-400 head-title title-right">
              Siema jestem Wynszer
            </h1>
            <div className="card no-hover" data-bs-theme="dark">
              <div className="card-body">
                <p className="text-white message message-right">
                  Ogólnie to zajmuję się YouTube już od jakoś 2 lat, lecz na
                  żadnym z poprzednich kanałów nie udało mi się wcześniej zdobyć
                  nawet tysiąca subskrybcji. Aktualnie uczę się w technikum
                  informatycznym, a w wolnym czasie zajmuję się kanałem YouTube,
                  programowaniem oraz tworzeniem grafik. Mam dwa koty: Batmana i
                  Puzona oraz psa Wintera :P
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <div className="text-left">
            <h1 className="display-4 text-white weight-400 head-title title-left">
              Jak zaczynałem
            </h1>
            <div className="card no-hover" data-bs-theme="dark">
              <div className="card-body">
                <p className="text-white message message-left">
                  Moja przygoda z YouTube zaczęła się od nagrania tutorialu do
                  kostki rubika. Tak. Potem próbowałem swoich sił grając w takie
                  gry jak Roblox czy Minecarft. Jednak po wakacjach 2023
                  stwierdziłem, że skoro i tak używam Discorda codziennie, to
                  dlaczego nie zacząć kanału opartego na tej platformie.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <p className="scroll-arrows" onClick={handleClick2}>
            <img
              style={{ marginTop: 0 }}
              src={arrow}
              alt="strzałka"
              className="arrows"
            />
          </p>
        </ScrollAnimation>
      </div>

      <div ref={ref2} className="part m-t-xl">
        <ScrollAnimation animateIn="fade-in" animateOnce={true}>
          <h1
            className="display-4 text-white weight-500"
            style={{ marginBottom: "1em" }}
          >
            Moje osiągnięcia
          </h1>

          <div className="cards ">
            {props.cards.map((card) => {
              return (
                <Card
                  link={card.link}
                  img={card.img}
                  img_alt={card.img_alt}
                  title={card.title}
                  description={card.description}
                />
              );
            })}
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
}
